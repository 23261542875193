import { useState } from 'react';
import { EXPLORE_PAGE, SEARCH_PAGE, headerLinks } from '../constants/routes';
import SearchButton from './Generic/SearchButton';
import { useNavigation } from './Router/NavigationContext';
import { isDarkModeEnabled, setDarkModeEnabled } from '../utils';
import DarkThemeIcon from './Icons/DarkThemeIcon';
import Link from './Generic/Link';

const WIDTH_THRESHOLD = 1024;

export default function Header() {
    const { currentPath } = useNavigation();
    const [darkMode, setDarkMode] = useState(isDarkModeEnabled());
    const [isMenuOpen, setIsMenuOpen] = useState(
        window.innerWidth > WIDTH_THRESHOLD,
    );

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLinkClick = (to) => {
        if (window.innerWidth <= WIDTH_THRESHOLD) {
            setIsMenuOpen(false);
        }
    };

    const handleDarkModeChange = () => {
        const value = !darkMode;
        setDarkMode(value);
        setDarkModeEnabled(value);
    };

    const handleResize = () => {
        if (window.innerWidth > WIDTH_THRESHOLD) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    };

    window.addEventListener('resize', handleResize);

    return (
        <header>
            <nav className="border-gray-200 bg-white px-4 py-2.5 dark:bg-gray-800 lg:px-6">
                <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between">
                    <Link href="/" className="flex items-center">
                        <img
                            src="https://avatars.githubusercontent.com/u/170185445?s=64"
                            className="mr-3 h-9 rounded-lg"
                            alt="Mouldahka Logo"
                        />
                        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                            Mouldahka
                        </span>
                    </Link>
                    <div className="flex items-center lg:order-2">
                        <SearchButton
                            className={
                                currentPath?.startsWith(EXPLORE_PAGE) &&
                                'text-primary-700 dark:text-white'
                            }
                        />
                        <button
                            onClick={() => handleDarkModeChange()}
                            className=""
                        >
                            <DarkThemeIcon className="h-5 w-5 fill-gray-400 dark:rotate-180 dark:fill-gray-400" />
                        </button>
                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="ml-1 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 lg:hidden"
                        >
                            <span className="sr-only">Ouvrir le menu</span>
                            <svg
                                className="h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <svg
                                className="hidden h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    {isMenuOpen && (
                        <div
                            className="w-full items-center justify-between lg:order-1 lg:flex lg:w-auto"
                            id="mobile-menu-2"
                        >
                            <ul className="mt-4 flex flex-col font-medium lg:mt-0 lg:flex-row lg:space-x-8">
                                {headerLinks.map(({ name, to }) => {
                                    return (
                                        <li
                                            className="flex justify-center"
                                            key={to}
                                        >
                                            <Link
                                                onClick={handleLinkClick}
                                                href={to}
                                                className={
                                                    to === currentPath
                                                        ? 'headerLink bg-primary-700 lg:text-primary-700 block rounded border-b border-gray-100 bg-gray-50 py-2 pl-3 pr-4 font-bold text-gray-900 dark:border-gray-700 dark:bg-gray-700 dark:text-white lg:border-0 lg:bg-transparent lg:p-0 dark:lg:bg-transparent'
                                                        : 'headerLink lg:hover:text-primary-700 block border-b border-gray-100 py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:dark:hover:bg-transparent lg:dark:hover:text-white'
                                                }
                                                aria-current="page"
                                            >
                                                {name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </nav>
        </header>
    );
}
