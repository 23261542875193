export const MEME_FORM = 'meme-form';
export const LOGIN_FORM = 'login-form';
export const ACTOR_FORM = 'actor-form';
export const PLATFORM_FORM = 'platform-form';

export const HOME_PAGE = '/';
export const NEW_MEME_PAGE = '/create/meme';
export const LOGIN_PAGE = '/login';
export const NEW_ACTOR_PAGE = '/create/actor';
export const NEW_PLATFORM_PAGE = '/create/platform';

export const MEME_PAGE = '/meme/:id';
export const SEARCH_PAGE = '/search';
export const EXPLORE_PAGE = '/explore';
export const NOT_FOUND_PAGE = '/404';

export const headerLinks = [
    { name: 'Accueil', to: HOME_PAGE },
    { name: 'Memes', to: NEW_MEME_PAGE },
    { name: 'Acteurs', to: NEW_ACTOR_PAGE },
    { name: 'Plateformes', to: NEW_PLATFORM_PAGE },
    { name: 'Explorer', to: EXPLORE_PAGE },
];

export const routes = [
    { name: 'Accueil', to: HOME_PAGE },
    { name: 'Memes', to: NEW_MEME_PAGE },
    { name: 'Acteurs', to: NEW_ACTOR_PAGE },
    { name: 'Plateformes', to: NEW_PLATFORM_PAGE },
    { name: 'Recherche', to: SEARCH_PAGE },
    { name: 'Explorer', to: EXPLORE_PAGE },
    { name: 'Page introuvable', to: NOT_FOUND_PAGE },
    { name: 'Meme', to: MEME_PAGE },
    { name: 'Connexion', to: LOGIN_PAGE },
];

export function getRoute(to) {
    to = to.split('?')[0];
    return routes.find((route) => {
        return (
            route.to.replace(/\/:[^/]+/g, '/[^/]+') ===
            to.replace(/\/[0-9]+/g, '/[^/]+')
        );
    });
}
