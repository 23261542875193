import { EXPLORE_PAGE } from '../../constants/routes';
import Link from './Link';

export default function SearchButton({ className }) {
    return (
        <Link
            href={EXPLORE_PAGE}
            className={
                'lg:hover:text-primary-700 ms-2 p-2.5 text-sm font-medium text-gray-400 lg:dark:hover:text-white ' +
                    className || ''
            }
        >
            <svg
                className="h-4 w-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
            >
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
            </svg>
            <span className="sr-only">Search</span>
        </Link>
    );
}
