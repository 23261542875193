import { components } from 'react-select';

export default function MultiValueContainer(props) {
    return (
        <span
            id="badge-dismiss-default"
            className="mb-1 mr-1 mt-1 inline-flex w-fit items-center gap-1 rounded bg-blue-100 px-2 py-1 text-sm font-medium text-blue-800 dark:bg-blue-900 dark:text-blue-300"
        >
            {props.data.label}
            <components.MultiValueRemove {...props} />
        </span>
    );
}
