import { isExternalLink } from '../../utils';
import { useNavigation } from '../Router/NavigationContext';

function Link({ href, children, target, onClick, className = '', params = {} }) {
    const { navigate } = useNavigation();

    const handleClick = (e, params = {}) => {
        if (!isExternalLink(href)) {
            e.preventDefault();
            navigate(href, params, {});
        }
        onClick && onClick(e);
    };

    return (
        <a
            href={href}
            className={className}
            target={target}
            onClick={e => { handleClick(e, params) }}
        >
            {children}
        </a>
    );
}

export default Link;
