import React, { createContext, useContext, useState, useEffect } from 'react';
import { getRoute, NOT_FOUND_PAGE } from '../../constants/routes';

const NavigationContext = createContext();

export const useNavigation = () => {
    return useContext(NavigationContext);
};

const extractParams = (path, pattern) => {
    const paramNames = [];
    const regexPath = pattern.replace(/\/:([^/]+)/g, (_, key) => {
        paramNames.push(key);
        return '/([^/]+)';
    });
    const splitedPath = path.split('?');
    const match = splitedPath[0].match(new RegExp(`^${regexPath}$`));
    if (!match) return { params: null, searchParams: null };

    const params = {};
    paramNames.forEach((name, index) => {
        params[name] = match[index + 1];
    });
    const searchParamsObj = new URLSearchParams(splitedPath[1]);
    const searchParams = {};
    for (const [key, value] of searchParamsObj) {
        searchParams[key] = value;
    }
    return { params, searchParams };
};

const getPath = () => {
    const path = window.location.href.substring(window.location.origin.length);
    return path.endsWith('/') && path !== '/' ? path.slice(0, -1) : path;
};

export const NavigationProvider = ({ children }) => {
    const [currentPath, setCurrentPath] = useState(getPath());
    const [params, setParams] = useState({});
    const [searchParams, setSearchParams] = useState({});
    const [context, setContext] = useState({});

    const navigate = (path, searchParams = {}, context = {}) => {
        path = `${path.split('?')[0]}${Object.keys(searchParams).length > 0 ? `?${new URLSearchParams(searchParams).toString()}` : ''}`;
        window.history.pushState({}, '', path);
        setCurrentPath(path);
        setContext(context);
    };

    useEffect(() => {
        const handlePopState = () => setCurrentPath(getPath());
        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, []);

    useEffect(() => {
        const route = getRoute(currentPath);
        document.title = `${route?.name || 'Page introuvable'} - Mouldahka`;
        if (!route) {
            navigate(NOT_FOUND_PAGE);
        }
    }, [currentPath]);

    return (
        <NavigationContext.Provider
            value={{
                currentPath,
                navigate,
                params,
                setParams,
                extractParams,
                searchParams,
                setSearchParams,
                context,
            }}
        >
            {children}
        </NavigationContext.Provider>
    );
};
