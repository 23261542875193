import { useEffect, useRef } from 'react';
import Link from '../components/Generic/Link';
import { HOME_PAGE } from '../constants/routes';

const NotFound = () => {
    const video = useRef(null);

    const play = () => {
        if (video.current) {
            video.current.play();
            document.removeEventListener('click', play);
        }
    };

    document.addEventListener('click', play);

    return (
        <div className="bg-white dark:bg-gray-900">
            <video
                ref={video}
                autoPlay
                loop
                playsinline
                className="absolute h-full w-full object-contain opacity-5"
            >
                <source
                    src="https://video.twimg.com/ext_tw_video/1809372754275364865/pu/vid/avc1/718x1242/O4jSxIIliGciqspG.mp4"
                    type="video/mp4"
                />
            </video>
            <div className="absolute flex h-full w-full items-center px-4 py-8 lg:px-6 lg:py-16">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="text-primary-600 dark:text-primary-500 mb-4 text-7xl font-extrabold tracking-tight text-blue-700 lg:text-9xl">
                        404
                    </h1>
                    <p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white md:text-4xl">
                        Chef, cheeef, CHEEEEF !
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        On a perdu cette page, mais on ne vous a pas perdu vous.
                    </p>
                    <Link
                        href={HOME_PAGE}
                        className="inline-flex items-center justify-center rounded-lg bg-blue-700 px-5 py-3 text-center text-base font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
                    >
                        Retour à l'accueil
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
