import { useRef, useState } from 'react';

export default function FileUpload() {
    const [file, setFile] = useState(null);
    const input = useRef(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);

        const file = event.target.files[0];
        const fileType = file.type;
        let previewUrl;

        if (fileType.startsWith('image/')) {
            previewUrl = URL.createObjectURL(file);
        } else if (fileType.startsWith('video/')) {
            previewUrl = URL.createObjectURL(file);
        }

        setPreviewUrl(previewUrl);
    };

    const dropHandler = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        input.current.files = event.dataTransfer.files;

        setFile(file);
        const fileType = file.type;
        let previewUrl;

        if (fileType.startsWith('image/')) {
            previewUrl = URL.createObjectURL(file);
        } else if (fileType.startsWith('video/')) {
            previewUrl = URL.createObjectURL(file);
        }

        setPreviewUrl(previewUrl);
    };

    const dragOverHandler = (event) => {
        event.preventDefault();
    };

    return (
        <div className="mb-5">
            <div
                className="flex w-full items-center justify-center"
                onDrop={dropHandler}
                onDragOver={dragOverHandler}
            >
                <label
                    htmlFor="dropzone-file"
                    className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                    {!file && (
                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                            <svg
                                className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                    Cliquer pour uploader
                                </span>{' '}
                                ou glisser-déposer
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                image ou vidéo (MAX 100Mo / 2:20min)
                            </p>
                        </div>
                    )}
                    <input
                        id="dropzone-file"
                        type="file"
                        ref={input}
                        className="hidden"
                        name="media"
                        accept="image/*, video/*"
                        onChange={handleFileChange}
                    />
                    {/* Place the preview code here */}
                    {file && (
                        <div className="left-0 top-0 flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700">
                            {previewUrl && file.type.startsWith('image/') ? (
                                <img
                                    src={previewUrl}
                                    alt="preview"
                                    className="h-full w-full rounded-lg object-cover"
                                />
                            ) : (
                                previewUrl &&
                                file.type.startsWith('video/') && (
                                    <video
                                        src={previewUrl}
                                        controls
                                        className="h-full w-full rounded-lg object-contain"
                                    />
                                )
                            )}
                        </div>
                    )}
                </label>
            </div>
            {file && (
                <button
                    onClick={() => {
                        setFile(null);
                        setPreviewUrl(null);
                    }}
                    className="text-xs text-red-500 dark:text-red-400"
                >
                    Retirer le fichier
                </button>
            )}
        </div>
    );
}
