import { API_URL } from '../constants/api';
import { getAuthHeaders } from '../utils';

export async function getAllActors() {
    const response = await fetch(`${API_URL}/api/actors/`, {
        headers: getAuthHeaders(),
    });
    return { code: response.status, data: await response.json() };
}

export async function getActorsRanking() {
    const response = await fetch(`${API_URL}/api/actors/ranking/`, {
        headers: getAuthHeaders(),
    });
    return { code: response.status, data: await response.json() };
}
