import { API_URL } from '../constants/api';
import { getAuthHeaders } from '../utils';

export async function searchMemes({ search, page = 1, actors = [] }) {
    const response = await fetch(
        `${API_URL}/api/memes/search/?search=${search}&page=${page}` +
            (actors.length ? `&actors=${actors.join(',')}` : ''),
        {
            headers: getAuthHeaders(),
        },
    );
    const data = await response.json();
    return {
        code: response.status,
        data,
        fetchNext: () => searchMemes({ search, page: page + 1 }),
        fetchPrevious: () => searchMemes({ search, page: page - 1 }),
    };
}

export async function getMemes(page = 1) {
    const response = await fetch(`${API_URL}/api/memes/`, {
        headers: getAuthHeaders(),
    });
    const data = await response.json();
    return {
        code: response.status,
        data,
        fetchNext: () => getMemes(page + 1),
        fetchPrevious: () => getMemes(page - 1),
    };
}

export async function getMeme(id) {
    const response = await fetch(`${API_URL}/api/memes/${id}/`, {
        headers: getAuthHeaders(),
    });
    const data = await response.json();
    return {
        ok: response.ok,
        code: response.status,
        data,
    };
}
