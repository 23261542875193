import Chip from '../../Generic/Chip';
import EnterKey from '../../Generic/EnterKey';
import Tooltip from '../../Generic/Tooltip';

export default function WordsField({ words, setWords }) {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (event.target.value === '') return;
            setWords([...words, event.target.value]);
            event.target.value = '';
            event.stopPropagation();
        }
    };
    return (
        <div className="mb-5">
            <label
                htmlFor="tags"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Paroles :
            </label>
            <Tooltip
                tooltipChildren={
                    <>
                        Appuye sur <EnterKey /> pour ajouter la phrase.
                    </>
                }
            >
                <input
                    type="text"
                    className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    onKeyDown={handleKeyDown}
                />
            </Tooltip>
            <p
                id="helper-text-explanation"
                className="mt-2 text-sm text-gray-500 dark:text-gray-400"
            >
                Les phrases de la vidéo ou la légende du meme.{' '}
                {words.length > 1 && (
                    <button
                        onClick={() => {
                            setWords([]);
                        }}
                        className="text-xs text-red-500 dark:text-red-400"
                    >
                        Tout effacer
                    </button>
                )}
            </p>
            <div className="mt-2 flex flex-col gap-1">
                {words.map((word, index) => (
                    <Chip
                        key={index}
                        text={word}
                        onRemove={() =>
                            setWords(words.filter((w) => w !== word))
                        }
                        clickable="true"
                    />
                ))}
            </div>
            <input name="words" type="hidden" value={JSON.stringify(words)} />
        </div>
    );
}
