import { MEME_PAGINATION_SIZE } from '../../constants/api';

function PaginationNav({ previous, current, next, count, onChange }) {
    const hasPrevious = previous !== null;
    const hasNext = next !== null;

    const handlePrevious = () => {
        if (hasPrevious) {
            onChange?.(current - 1);
        }
    };

    const handleNext = () => {
        if (hasNext) {
            onChange?.(current + 1);
        }
    };

    const handlePage = (page) => {
        onChange?.(page);
    };

    return (
        <nav aria-label="Page navigation example">
            <ul className="inline-flex h-10 -space-x-px text-base">
                <li>
                    <button
                        onClick={handlePrevious}
                        className={`ms-0 flex h-10 items-center justify-center rounded-s-lg border border-gray-300 bg-white px-4 leading-tight text-gray-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 ${
                            !hasPrevious
                                ? 'cursor-not-allowed opacity-60'
                                : 'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white'
                        }`}
                    >
                        Précédent
                    </button>
                </li>
                {Array.from({
                    length: Math.ceil(count / MEME_PAGINATION_SIZE),
                }).map((_, index) => (
                    <li key={index}>
                        <button
                            onClick={() => handlePage(index + 1)}
                            className={
                                current === index + 1
                                    ? 'flex h-10 items-center justify-center border border-gray-300 bg-gray-100 px-4 leading-tight text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                    : 'flex h-10 items-center justify-center border border-gray-300 bg-white px-4 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            }
                        >
                            {index + 1}
                        </button>
                    </li>
                ))}
                <li>
                    <button
                        onClick={handleNext}
                        className={`flex h-10 items-center justify-center rounded-e-lg border border-gray-300 bg-white px-4 leading-tight text-gray-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 ${
                            !hasNext
                                ? 'cursor-not-allowed opacity-60'
                                : 'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white'
                        }`}
                    >
                        Suivant
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default PaginationNav;
