import Chip from '../../Generic/Chip';
import EnterKey from '../../Generic/EnterKey';
import Tooltip from '../../Generic/Tooltip';

export default function TagsField({
    values,
    setValues,
    name,
    helpText,
    title,
    tooltip,
}) {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (event.target.value === '') return;
            setValues([...values, event.target.value]);
            event.target.value = '';
            event.stopPropagation();
        }
    };

    return (
        <div className="mb-5">
            <label
                htmlFor={name}
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                {title} :
            </label>

            {tooltip ? (
                <Tooltip
                    tooltipChildren={
                        <>
                            Appuye sur <EnterKey /> pour ajouter le mot-clé.
                        </>
                    }
                >
                    <input
                        type="text"
                        className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        onKeyDown={handleKeyDown}
                    />
                </Tooltip>
            ) : (
                <input
                    type="text"
                    className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    onKeyDown={handleKeyDown}
                />
            )}
            <p
                id="helper-text-explanation"
                className="mt-2 text-sm text-gray-500 dark:text-gray-400"
            >
                {helpText}{' '}
                {values.length > 1 && (
                    <button
                        onClick={() => {
                            setValues([]);
                        }}
                        className="text-xs text-red-500 dark:text-red-400"
                    >
                        Tout effacer
                    </button>
                )}
            </p>
            <div className="mt-2 flex flex-wrap gap-2">
                {values.map((tag, index) => (
                    <Chip
                        key={index}
                        text={tag}
                        onRemove={() =>
                            setValues(values.filter((t) => t !== tag))
                        }
                        clickable="true"
                    />
                ))}
            </div>
            <input name={name} type="hidden" value={JSON.stringify(values)} />
        </div>
    );
}
