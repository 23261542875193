import { useEffect, useState } from 'react';
import { getActorsRanking } from '../../shared/actor.service';
import { EXPLORE_PAGE } from '../../constants/routes';
import { useNavigation } from '../Router/NavigationContext';

function ActorsRanking({ className }) {
    const { navigate } = useNavigation();
    const [actors, setActors] = useState([]);

    useEffect(() => {
        getActorsRanking().then(({ data }) => setActors(data));
    }, []);

    return (
        <table
            className={`overflow-hidden rounded-lg text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right ${className}`}
        >
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="w-10 py-3 pl-6">
                        Pos.
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Nom
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Memes
                    </th>
                </tr>
            </thead>
            <tbody>
                {actors.map(
                    ({ id, name, num_memes, avatar, aliases }, index) => (
                        <tr
                            onClick={() => navigate(EXPLORE_PAGE, { q: name })}
                            className={`${index !== actors.length - 1 && 'border-b'} cursor-pointer bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600`}
                            key={`actor-${id}-${index}`}
                        >
                            <td className="py-4 pl-6 text-gray-500 dark:text-gray-400">
                                {index + 1}.
                            </td>
                            <th
                                scope="row"
                                className="flex items-center whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white"
                            >
                                {avatar && (
                                    <img
                                        className="h-10 w-10 rounded-full"
                                        src={avatar}
                                        alt={name}
                                    />
                                )}
                                <div className={avatar && 'ps-3'}>
                                    <div className="text-base font-semibold">
                                        {name}
                                    </div>
                                    <div className="font-normal text-gray-500">
                                        {aliases && aliases.join(', ')}
                                    </div>
                                </div>
                            </th>
                            <td className="px-6 py-4">{num_memes}</td>
                        </tr>
                    ),
                )}
            </tbody>
        </table>
    );
}

export default ActorsRanking;
