import { useState } from 'react';
import ShareIcon from '../Icons/ShareIcon';

function ShareButton({ title, text, url }) {
    const [label, setLabel] = useState('Partager');

    return (
        <button
            onClick={() => {
                try {
                    navigator.share({
                        title,
                        text,
                        url,
                    });
                } catch (e) {}
                navigator.clipboard.writeText(url);
                setLabel('Lien copié !');
                setTimeout(() => {
                    setLabel('Partager');
                }, 2000);
            }}
            type="button"
            className="dark:hover:bg-gray-70 inline-flex items-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-center text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-gray-700"
        >
            <ShareIcon className="me-2" />
            {label}
        </button>
    );
}

export default ShareButton;
