import React, { createContext, useContext } from 'react';
import { useEffect, useState } from 'react';
import { getAllActors } from '../shared/actor.service';
import { getAllPlatforms } from '../shared/platform.service';

const AppContext = createContext();

export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
    const [actors, setActors] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('token'));

    useEffect(() => {
        if (token) {
            getAllActors().then(({ data }) => {
                setActors(data);
            });
            getAllPlatforms().then(({ data }) => {
                setPlatforms(data);
            });
        }
    }, [token]);

    return (
        <AppContext.Provider
            value={{
                actors,
                platforms,
                platformsMap: platforms.reduce((acc, platform) => {
                    acc[platform.id] = platform.name;
                    return acc;
                }, {}),
                token,
                setToken,
                setActors,
                setPlatforms,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
