import { useEffect, useRef, useState } from 'react';
import PlayIcon from '../Icons/PlayIcon';

function Video({
    source,
    type,
    alt,
    properties,
    preload,
    playIcon,
    className,
    ambilight,
}) {
    const [loaded, setLoaded] = useState(false);
    const containerRef = useRef();
    const videoRef = useRef();
    const canvasRef = useRef();

    const handleFullscreen = () => {
        if (!videoRef.current) return;
        let fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement;

        videoRef.current.classList.toggle(
            'object-cover',
            fullscreenElement !== videoRef.current,
        );
    };

    document.addEventListener('fullscreenchange', handleFullscreen);
    document.addEventListener('webkitfullscreenchange', handleFullscreen);
    document.addEventListener('mozfullscreenchange', handleFullscreen);
    document.addEventListener('MSFullscreenChange', handleFullscreen);

    useEffect(() => {
        if (ambilight) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const container = containerRef.current;

            if (!video || !canvas || !container) return;

            const ctx = canvas.getContext('2d');
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;

            const draw = () => {
                canvas.width = container.clientWidth;
                canvas.height = container.clientHeight;
                ctx.drawImage(
                    video,
                    0,
                    0,
                    container.clientWidth,
                    container.clientHeight,
                );
                requestAnimationFrame(draw);
            };

            video.addEventListener('play', () => {
                draw();
            });
        }
    }, [videoRef, canvasRef, containerRef, source]);

    return (
        <div
            className={`${className} flex items-center justify-center`}
            ref={containerRef}
        >
            {!loaded && preload && (
                <div
                    className={`${properties?.video?.className} flex animate-pulse items-center justify-center rounded bg-gray-300 dark:bg-gray-700`}
                >
                    <svg
                        className="h-10 w-10 text-gray-200 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 16 20"
                    >
                        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                        <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                    </svg>
                </div>
            )}
            {playIcon && (
                <PlayIcon
                    className={`${properties?.playIcon?.className} absolute h-16 w-16 text-gray-200 dark:text-gray-600`}
                />
            )}
            {ambilight && (
                <div className="absolute rounded-lg" style={{ zIndex: 0 }}>
                    <canvas
                        className={`rounded-lg blur-3xl ${properties?.ambilight?.className} opacity-70`}
                        ref={canvasRef}
                    />
                </div>
            )}
            <video
                style={{
                    ...(loaded || !preload ? {} : { display: 'none' }),
                    ...(ambilight ? { zIndex: 1 } : {}),
                }}
                className={properties?.video?.className}
                preload={preload}
                onLoad={() => setLoaded(true)}
                ref={videoRef}
                {...properties?.video}
            >
                <source src={source} type={type} />
            </video>
        </div>
    );
}

export default Video;
