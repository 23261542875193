export default function Chip({ icon, text, onRemove, color = 'blue', clickable = 'false' }) {
    let containerClasses, buttonClasses;
    switch (color) {
        case 'blue':
            containerClasses =
                'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300';
            buttonClasses =
                'text-blue-400 hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300';
            break;
        case 'gray':
            containerClasses =
                `border-gray-300 bg-white text-gray-900 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:focus:ring-gray-700 border dark:border-0 border-gray-200 dark:border-gray-600 ${clickable ? 'dark:hover:bg-gray-700' : ''}`;
            buttonClasses =
                'text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-300';
            break;
        case 'emerald':
            containerClasses =
                'bg-emerald-800 text-white';
            buttonClasses = 'bg-emerald-800 text-white hover:bg-emerald-600';
            break;
    }
    return (
        <span
            id="badge-dismiss-default"
            className={`inline-flex w-fit items-center rounded px-2 py-1 text-sm font-medium ${containerClasses}`}
        >
            {icon && (
                <span className="me-1 flex h-4 w-4 items-center justify-center">
                    {icon}
                </span>
            )}
            {text}
            {clickable === 'true' && onRemove && (
                <button
                    type="button"
                    className={`ms-2 inline-flex items-center rounded-sm bg-transparent p-1 text-sm ${buttonClasses}`}
                    data-dismiss-target="#badge-dismiss-default"
                    aria-label="Remove"
                    onClick={onRemove}
                >
                    <svg
                        className="h-2 w-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                    </svg>
                    <span className="sr-only">Retirer</span>
                </button>
            )}
        </span>
    );
}
