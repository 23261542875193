import './App.css';
import MemeForm from './components/Forms/MemeForm';
import LoginForm from './components/Forms/LoginForm';
import ActorForm from './components/Forms/ActorForm';
import PlatformForm from './components/Forms/PlatformForm';
import {
    ACTOR_FORM,
    EXPLORE_PAGE,
    HOME_PAGE,
    MEME_FORM,
    MEME_PAGE,
    NEW_ACTOR_PAGE,
    NEW_MEME_PAGE,
    NEW_PLATFORM_PAGE,
    NOT_FOUND_PAGE,
    PLATFORM_FORM,
} from './constants/routes';
import Header from './components/Header';
import Router from './components/Router/Router';
import Route from './components/Router/Route';
import MemePage from './pages/MemePage';
import SearchPage from './pages/SearchPage';
import { useAppContext } from './context/AppContext';
import HomePage from './pages/HomePage';
import NotFound from './pages/NotFound';

function Section({ children, id }) {
    return <section id={id}>{children}</section>;
}

function App() {
    const { token } = useAppContext();

    return token ? (
        <Router>
            <main className="">
                <Header />

                <Route path={HOME_PAGE}>
                    <Section>
                        <HomePage />
                    </Section>
                </Route>
                <Route path={NEW_MEME_PAGE} keep>
                    <Section id={MEME_FORM}>
                        <MemeForm />
                    </Section>
                </Route>
                <Route path={NEW_ACTOR_PAGE} keep>
                    <Section id={ACTOR_FORM}>
                        <ActorForm />
                    </Section>
                </Route>
                <Route path={NEW_PLATFORM_PAGE} keep>
                    <Section id={PLATFORM_FORM}>
                        <PlatformForm />
                    </Section>
                </Route>
                <Route path={MEME_PAGE}>
                    <Section id={MEME_PAGE}>
                        <MemePage />
                    </Section>
                </Route>
                <Route path={EXPLORE_PAGE} keep>
                    <Section id={EXPLORE_PAGE}>
                        <SearchPage />
                    </Section>
                </Route>
                <Route path={NOT_FOUND_PAGE}>
                    <NotFound />
                </Route>
            </main>
        </Router>
    ) : (
        <LoginForm />
    );
}

export default App;
