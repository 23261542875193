import WordsField from '../Forms/Fields/WordsField';
import FileUpload from '../Forms/Fields/FileUpload';
import Toast from '../Generic/Toast';
import Select, { components } from 'react-select';
import TagsField from '../Forms/Fields/TagsField';
import { useState } from 'react';
import { memeFormFilters, memeFormErrorMessages } from '../../constants/form';
import MultiValueContainer from '../Generic/MultiValueContainer';
import MultiValueRemove from '../Generic/MultiValueRemove';
import {
    ACTOR_FORM,
    MEME_FORM,
    NEW_ACTOR_PAGE,
    NEW_MEME_PAGE,
    NEW_PLATFORM_PAGE,
    PLATFORM_FORM,
} from '../../constants/routes';
import { alphabeticallySort } from '../../utils';
import { useNavigation } from '../Router/NavigationContext';
import { useAppContext } from '../../context/AppContext';
import Link from '../Generic/Link';
import { API_URL } from '../../constants/api';

export default function MemeForm() {
    const [selectedActors, setSelectedActors] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [tags, setTags] = useState([]);
    const [words, setWords] = useState([]);
    const { currentPath } = useNavigation();
    const { actors, platforms, token } = useAppContext();

    const clearForm = () => {
        setTags([]);
        setWords([]);
        setSelectedActors([]);
    };

    const handleCreateMeme = (event) => {
        event.preventDefault();
        const form = document.querySelector(`form#${MEME_FORM}`);
        if (!form || currentPath !== NEW_MEME_PAGE) return;
        const data = new FormData(form);
        let isOk = true;
        for (let key of data.keys()) {
            const field = form.querySelector(`[name=${key}]`);
            if (!memeFormFilters[key](data.get(key))) {
                setError(memeFormErrorMessages[key]);
                field.focus();
                isOk = false;
                return;
            }
        }
        if (!isOk) return;

        fetch(`${API_URL}/api/memes/create/`, {
            method: 'POST',
            headers: {
                Authorization: `Token ${token}`,
            },
            body: data,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setError(data.error);
                } else {
                    setError(null);
                    setSuccess(true);
                    clearForm();
                    form.reset();
                }
            });
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="mb-10 mt-10 text-center text-4xl font-bold dark:text-white">
                Créé{' '}
                <span className="text-blue-600 dark:text-blue-500">ton</span>{' '}
                meme
            </h2>
            {error && (
                <Toast
                    message={error}
                    type="warning"
                    onClose={() => setError(null)}
                />
            )}
            <form
                onSubmit={handleCreateMeme}
                className="w-96 md:w-1/3"
                id={MEME_FORM}
            >
                <div className="mb-5">
                    <label
                        htmlFor="title"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Titre :
                    </label>
                    <input
                        type="text"
                        name="title"
                        className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    />
                    <p
                        id="helper-text-explanation"
                        className="mt-2 text-sm text-gray-500 dark:text-gray-400"
                    >
                        Le titre du meme.
                    </p>
                </div>
                <div className="mb-5">
                    <label
                        htmlFor="actors"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Acteurs :
                    </label>
                    <Select
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={actors
                            .map((actor) => ({
                                value: actor.id,
                                label: actor.name,
                            }))
                            .toSorted(alphabeticallySort('label'))}
                        placeholder="Séléctionner les acteurs"
                        className="my-react-select-container mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                        classNamePrefix="my-react-select"
                        components={{
                            MultiValueContainer: MultiValueContainer,
                            MultiValueLabel: (props) => null,
                            MultiValueRemove: MultiValueRemove,
                        }}
                        onChange={(actors) =>
                            setSelectedActors(
                                actors.map((actor) => Number(actor.value)),
                            )
                        }
                    />
                    <p
                        id="helper-text-explanation"
                        className="mt-2 text-sm text-gray-500 dark:text-gray-400"
                    >
                        Les acteurs présents dans le meme.{' '}
                        <Link
                            className="rounded-lg text-center text-sm text-xs font-medium text-blue-600 hover:underline focus:outline-none focus:ring-4 focus:ring-blue-300 dark:text-blue-500"
                            href={NEW_ACTOR_PAGE}
                        >
                            Ajouter un acteur
                        </Link>
                    </p>
                    <input
                        name="actors"
                        type="hidden"
                        value={((selectedActors) => {
                            try {
                                return JSON.stringify(selectedActors);
                            } catch (e) {
                                return '[]';
                            }
                        })(selectedActors)}
                    />
                </div>
                <div className="mb-5">
                    <label
                        htmlFor="platform"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Plateforme :
                    </label>
                    <Select
                        options={platforms
                            .map((platform) => ({
                                value: platform.id,
                                label: platform.name,
                            }))
                            .toSorted(alphabeticallySort('label'))}
                        placeholder="Séléctionner la plateforme"
                        className="my-react-select-container mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                        classNamePrefix="my-react-select"
                        name="platform"
                    />
                    <p
                        id="helper-text-explanation"
                        className="mt-2 text-sm text-gray-500 dark:text-gray-400"
                    >
                        La plateforme dont le meme est issu (facilite la
                        recherche).{' '}
                        <Link
                            className="rounded-lg text-center text-sm text-xs font-medium text-blue-600 hover:underline focus:outline-none focus:ring-4 focus:ring-blue-300 dark:text-blue-500"
                            href={NEW_PLATFORM_PAGE}
                        >
                            Ajouter une plateforme
                        </Link>
                    </p>
                </div>
                <TagsField
                    values={tags}
                    setValues={setTags}
                    name="tags"
                    title="Mots-clés"
                    helpText="Ajouter des mots-clés au meme (facilite le référencement)."
                />
                <WordsField words={words} setWords={setWords} />
                <FileUpload />
                {success && (
                    <Toast
                        message="Meme envoyé pour vérification"
                        type="success"
                        onClose={() => setSuccess(false)}
                    />
                )}
                <button
                    type="submit"
                    className="mb-5 rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Envoyer le meme
                </button>
            </form>
        </div>
    );
}
