import ActorsRanking from '../components/Actors/ActorsRanking';
import Link from '../components/Generic/Link';
import { EXPLORE_PAGE, NEW_MEME_PAGE, SEARCH_PAGE } from '../constants/routes';

function HomePage() {
    return (
        <main>
            <section className="relative bg-white bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-gray-900 dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
                <div className="relative z-10 mx-auto max-w-screen-xl px-4 py-8 text-center lg:py-16">
                    <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
                        Tous les memes au même endroit
                    </h1>
                    <p className="mb-8 text-lg font-normal text-gray-500 dark:text-gray-200 sm:px-16 lg:px-48 lg:text-xl">
                        Découvrez les derniers memes, partagez les vôtres et
                        explorez des plus populaires aux plus nichés.
                    </p>
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                        <Link
                            href={EXPLORE_PAGE}
                            className="inline-flex items-center justify-center rounded-lg bg-blue-700 px-5 py-3 text-center text-base font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
                        >
                            Explorer les memes
                            <svg
                                className="ms-2 h-3.5 w-3.5 rtl:rotate-180"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                            </svg>
                        </Link>
                        <Link
                            href={NEW_MEME_PAGE}
                            className="dark:hover:bg-gray-70 rounded-lg border border-gray-200 bg-white px-5 py-3 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-gray-700 sm:ms-4"
                        >
                            Ajouter un meme
                        </Link>
                    </div>
                </div>
                {/* <div className="bg-gradient-to-b from-blue-50 to-transparent dark:from-blue-900 w-full h-full absolute top-0 left-0 z-0"></div> */}
            </section>

            <section className="mx-auto w-full max-w-screen-xl px-4 py-12 lg:py-16">
                <div className="wrap mx-auto items-center justify-between gap-8 p-4 pb-8 md:flex md:p-20">
                    <div>
                        <h2 className="mb-4 text-3xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-4xl">
                            Les acteurs majeurs du meme
                        </h2>
                        <p className="mb-8 text-lg font-normal text-gray-500 dark:text-gray-200">
                            Découvrez les personnalités les plus influentes dans
                            le meme game.
                        </p>
                    </div>

                    <div className="flex items-center justify-center">
                        <ActorsRanking className="shadow-2xl" />
                    </div>
                </div>
            </section>
        </main>
    );
}

export default HomePage;
