import { useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { login } from '../../shared/auth.service';

export default function LoginForm() {
    const [error, setError] = useState(null);
    const { setToken } = useAppContext();

    const handleLogin = async (event) => {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);

        try {
            const response = await login(data);
            localStorage.setItem('token', response.token);
            setToken(response.token);
        } catch (error) {
            setError("Nom d'utilisateur ou mot de passe incorrect");
        }
    };

    return (
        <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
            <p
                href="#"
                className="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
            >
                Mouldahka
            </p>
            <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
                <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
                        Connecte toi à ton compte
                    </h1>
                    <form
                        className="space-y-4 md:space-y-6"
                        onSubmit={handleLogin}
                    >
                        <div>
                            <label
                                htmlFor="username"
                                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Nom d'utilisateur
                            </label>
                            <input
                                type="text"
                                name="username"
                                id="username"
                                className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                                placeholder="aziz"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="password"
                                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Mot de passe
                            </label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="••••••••"
                                className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                            />
                        </div>
                        {error && (
                            <div className="text-sm font-medium text-red-500">
                                {error}
                            </div>
                        )}
                        <button
                            type="submit"
                            className="w-full rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Se connecter
                        </button>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Pas encore de compte ?{' '}
                            <p
                                href="#"
                                className="text-primary-600 dark:text-primary-500 font-medium hover:underline"
                            >
                                S'inscrire
                            </p>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}
