import { API_URL } from '../constants/api';

export async function login(data) {
    const response = await fetch(`${API_URL}/api/auth/login/`, {
        method: 'POST',
        body: data,
    });
    const { token } = await response.json();
    return { code: response.status, token };
}
