import { isNonEmptyArrayOfIntegers, isArrayOfStrings } from '../utils';

export const memeFormFilters = {
    title: (value) => Boolean(value) && value.trim().length > 2,
    actors: (value) => isNonEmptyArrayOfIntegers(value),
    platform: (value) => Boolean(value) && value.match(/^\d+$/),
    tags: isArrayOfStrings,
    words: isArrayOfStrings,
    media: (value) => Boolean(value),
};

export const memeFormErrorMessages = {
    title: 'Le titre doit contenir au moins 3 caractères.',
    actors: 'Au moins un acteur doit être sélectionné.',
    platform: 'Une plateforme doit être sélectionnée.',
    tags: 'Les tags doivent contenir des mots.',
    words: 'Les paroles doivent contenir des mots.',
    media: 'Un fichier doit être sélectionné.',
};

export const actorFormFilters = {
    name: (value) => Boolean(value) && value.trim().length > 2,
    aliases: isArrayOfStrings,
};

export const actorFormErrorMessages = {
    name: 'Le nom doit contenir au moins 3 caractères.',
    aliases: 'Les alias doivent contenir des mots.',
};

export const platformFormFilters = {
    name: (value) => Boolean(value) && value.trim().length > 1,
};

export const platformFormErrorMessages = {
    name: 'Le nom doit contenir au moins 2 caractères.',
};
