import { useState } from 'react';

function Image({ src, alt, className, allowFullScreen, width, onClick }) {
    const [loaded, setLoaded] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    return (
        <>
            {!loaded && (
                <div
                    className={`${className} flex animate-pulse items-center justify-center rounded bg-gray-300 dark:bg-gray-700`}
                >
                    <svg
                        className="h-10 w-10 text-gray-200 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 18"
                    >
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                </div>
            )}
            <img
                style={loaded ? {} : { display: 'none' }}
                src={src}
                alt={alt}
                className={`${className} ${allowFullScreen ? 'cursor-zoom-in' : ''}`}
                onLoad={() => setLoaded(true)}
                width={width}
                onClick={() => {
                    if (allowFullScreen) setFullScreen(true);
                    if (onClick) onClick();
                }}
            />
            {allowFullScreen && fullScreen && (
                <div
                    className="fixed inset-0 z-50 flex cursor-zoom-out items-center justify-center bg-black bg-opacity-50 px-4 py-2.5 backdrop-blur"
                    onClick={() => setFullScreen(false)}
                >
                    <img
                        src={src}
                        alt={alt}
                        className="max-w-screen shadow-x max-h-screen cursor-default rounded-lg"
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            )}
        </>
    );
}

export default Image;
