import React, { useEffect } from 'react';
import { useNavigation } from './NavigationContext';

const Route = ({ path, children, keep }) => {
    const { currentPath, extractParams, setParams, context, setSearchParams } =
        useNavigation();

    useEffect(() => {
        const { params, searchParams } = extractParams(currentPath, path);
        if (params) {
            setParams(params);
        }
        if (searchParams) {
            setSearchParams(searchParams);
        }
    }, [currentPath, path, extractParams, setParams, setSearchParams]);

    const { params, searchParams } = extractParams(currentPath, path);

    return keep ? (
        <div style={{ display: params ? 'block' : 'none' }}>
            {React.cloneElement(children, { params, context })}
        </div>
    ) : params ? (
        React.cloneElement(children, { params, context })
    ) : null;
};

export default Route;
