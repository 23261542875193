import Chip from '../Generic/Chip';
import UserIcon from '../Icons/UserIcon';
import Image from '../Generic/Image';
import Video from '../Generic/Video';
import { useAppContext } from '../../context/AppContext';
import ShareButton from '../Generic/ShareButton';
import { useNavigation } from '../Router/NavigationContext';
import { MEME_PAGE } from '../../constants/routes';
import Link from '../Generic/Link';

function MemeContent({ meme }) {
    const { platformsMap } = useAppContext();

    return (
        <>
            <div className="p-4">
                <div className="flex items-center justify-center rounded-lg">
                    {new URL(meme.url).hostname.match(/video/) ? (
                        <Video
                            className="flex-1 rounded-lg bg-gray-950"
                            properties={{
                                video: {
                                    className:
                                        'max-h-96 w-full rounded-lg object-contain',
                                    controls: true,
                                    autoPlay: true,
                                },
                            }}
                            source={meme.url}
                            type="video/mp4"
                        />
                    ) : (
                        <Link href={MEME_PAGE.replace(':id', meme.id)}>
                            <Image
                                className="max-h-96 w-full cursor-pointer rounded-lg object-cover"
                                src={meme.url}
                                alt={meme.title}
                            />
                        </Link>
                    )}
                </div>
                <div className="mt-2 flex justify-between">
                    <div className="flex flex-wrap gap-2">
                        {meme.actors.map((actor) => (
                            <Chip
                                key={actor.id}
                                text={actor.name}
                                icon={<UserIcon />}
                            />
                        ))}
                    </div>
                    <ShareButton
                        title={meme.title}
                        url={meme.share_url}
                        text={meme.actors.map((actor) => actor.name).join(', ')}
                    />
                </div>
            </div>
            <div className="p-4">
                <p className="text-sm font-medium dark:text-white">
                    Plateforme :{' '}
                    <span className="text-sm font-normal">
                        {platformsMap[meme.platform]}
                    </span>
                </p>
            </div>
            {!['', ' '].includes(meme.words[0]) && (
                <div className="p-4">
                    <p className="max-w-prose text-sm font-medium dark:text-white">
                        Paroles :{' '}
                        <ul className="ml-5 mt-2 list-['-_'] font-normal italic text-gray-500 dark:text-gray-400">
                            {meme.words.map((word) => (
                                <li>{word}</li>
                            ))}
                        </ul>
                    </p>
                </div>
            )}
            <div className="p-4">
                <p className="flex flex-wrap items-center gap-2 text-sm font-medium dark:text-white">
                    Tags :{' '}
                    <div className="flex flex-wrap gap-1">
                        {meme.tags.map((tag, index) => (
                            <Chip key={index} text={tag} color="gray" />
                        ))}
                    </div>
                </p>
            </div>
        </>
    );
}

export default MemeContent;
