export default function Tooltip({ children, tooltipChildren }) {
    return (
        <div className="group relative flex">
            {children}
            <span
                role="tooltip"
                className="tooltip absolute bottom-12 z-10 rounded bg-gray-800 p-2 px-3 py-2 text-xs text-white opacity-0 shadow-md transition-opacity duration-300 group-focus-within:opacity-100"
            >
                {tooltipChildren}
            </span>
        </div>
    );
}
