import Toast from '../Generic/Toast';
import { useState } from 'react';
import {
    platformFormFilters,
    platformFormErrorMessages,
} from '../../constants/form';
import {
    PLATFORM_FORM,
    MEME_FORM,
    NEW_MEME_PAGE,
} from '../../constants/routes';
import { useNavigation } from '../Router/NavigationContext';
import { useAppContext } from '../../context/AppContext';
import Link from '../Generic/Link';
import { API_URL } from '../../constants/api';

export default function PlatformForm() {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { token, platforms, setPlatforms } = useAppContext();

    const handleCreatePlatform = (event) => {
        event.preventDefault();
        const form = document.querySelector(`form#${PLATFORM_FORM}`);
        const data = new FormData(form);
        let isOk = true;
        for (let key of data.keys()) {
            const field = form.querySelector(`[name=${key}]`);
            if (!platformFormFilters[key](data.get(key))) {
                setError(platformFormErrorMessages[key]);
                field.focus();
                isOk = false;
                return;
            }
        }
        if (!isOk) return;

        fetch(`${API_URL}/api/platforms/`, {
            method: 'POST',
            headers: {
                Authorization: `Token ${token}`,
            },
            body: data,
        }).then(async (response) => {
            if (response.status === 403) {
                setError(
                    "Vous n'avez pas les droits pour effectuer cette action.",
                );
            } else {
                const data = await response.json();
                if (data.error) {
                    setError(data.error);
                } else {
                    setError(null);
                    setSuccess(true);
                    setPlatforms([...platforms, data]);
                    form.reset();
                }
            }
        });
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="mb-10 mt-10 text-center text-4xl font-bold dark:text-white">
                Créé{' '}
                <span className="text-blue-600 dark:text-blue-500">une</span>{' '}
                plateforme
            </h2>
            {error && (
                <Toast
                    message={error}
                    type="warning"
                    onClose={() => setError(null)}
                />
            )}
            <form
                onSubmit={handleCreatePlatform}
                className="w-96 md:w-1/3"
                id={PLATFORM_FORM}
            >
                <div className="mb-5">
                    <label
                        htmlFor="title"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Nom :
                    </label>
                    <input
                        type="text"
                        name="name"
                        className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    />
                    <p
                        id="helper-text-explanation"
                        className="mt-2 text-sm text-gray-500 dark:text-gray-400"
                    >
                        Le nom de la plateforme (ex: Snapchat, Twitter...).
                    </p>
                </div>
                {success && (
                    <Toast
                        message="Plateforme créée avec succès."
                        type="success"
                        onClose={() => setSuccess(false)}
                    />
                )}
                <div className="flex gap-1">
                    <Link
                        href={NEW_MEME_PAGE}
                        type="button"
                        className="me-2 inline-flex h-10 items-center rounded-lg bg-slate-700 p-2.5 text-center text-sm font-medium text-white hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                    >
                        <svg
                            className="h-4 w-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                            style={{ transform: 'rotate(180deg)' }}
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                        </svg>
                        <span className="sr-only">Icon description</span>
                    </Link>
                    <button
                        type="submit"
                        className="mb-5 h-10 rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Créer la plateforme
                    </button>
                </div>
            </form>
        </div>
    );
}
